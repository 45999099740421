<template>
	<el-upload action="" :on-change="handleChange" accept="image/*" :auto-upload="false" :show-file-list="false" drag :disabled="disabled">
		<i class="el-icon-upload" />
		<div class="el-upload__text">將圖片拖到此處，或<em>點擊上傳</em></div>
		<div class="el-upload__tip" slot="tip">最大不超過5MB</div>
	</el-upload>
</template>

<script>
import { mapActions } from "vuex";
export default {
	props: {
		disabled: Boolean
	},
	methods: {
		...mapActions("upload", ["_uploadImage"]),
		async handleChange({ raw }, fileList) {
			const data = new FormData();
			data.append("file", raw);
			this.$emit(
				"change",
				await this._uploadImage({
					data,
					folder: this.$route.path
						.split("/")
						.filter(item => item && item != "manager")
						.join("-"),
				})
			);
		},
	}
}
</script>